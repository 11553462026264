// Variables
@import 'variables';

// spinner large
.spinner-border-lg{
    width: $spinner-width-lg;
    height: $spinner-height-lg;
    border-width: $spinner-border-width-lg;
}

.spinner-grow-lg {
    width: $spinner-width-lg;
    height: $spinner-height-lg;
  }
